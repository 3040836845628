import React from 'react'
import Header from './Header'
import Footer from './Footer'
import ClientBody from './ClientBody'

const Client = () => {
  return (
    
    <>
   <Header/>
   <ClientBody/>
   <Footer/>
   </>
  )
}

export default Client