import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Feature from './Feature'

const Features = () => {
  return (
    
  <>
  <Header/>
  <Feature/>
  <Footer/>
  </>

  )
}

export default Features