import React from 'react'
import Header from './Header'
import Footer from './Footer'
import AboutBody from './AboutBody'

const About = () => {
  return (
    
    <>
    <Header/>
    <AboutBody/>
    <Footer/>
    </>

  )
}

export default About