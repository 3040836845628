import React from 'react'
import Header from './Header'
import Footer from './Footer'
import ContactBody from './ContactBody'

const Contact = () => {
  return (
    
  <>
   <Header/>
   <ContactBody/>
   <Footer/>
  </>

  )
}

export default Contact